import React, { useState } from 'react';
import CreateGroup from './newGroup';
import NewGroup from './newGroup';
import ModifyGroup from './modifyGroup';
import { useUserLevel, AdminLevel } from '../../admin';
import { group } from 'console';
import { setGroupDataView } from '../../routes/userprofile';

interface GroupProps {
  id: string;
  isGroupMember: boolean
}
const Group: React.FC<GroupProps> = ({id, isGroupMember}) => {
  const [visible, setVisible] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const { currentUserLevel } = useUserLevel();

  const handleCancel = () => {
    setVisible(false);
    setSelectedOption(null);
  };

  const handleMenuClick = (option: string) => {
    setSelectedOption(option);
    setVisible(true);
  };

  switch(currentUserLevel){
    case AdminLevel.SUPER_ADMIN:
      return  <div>
      <div className="dropdown">
        <label tabIndex={0} className="btn mx-2 btn-inactive">Groups</label>
        <ul tabIndex={0} className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52 z-50">
          <li onClick={() => handleMenuClick('create')}><a>New Group</a></li>
          <li onClick={() => handleMenuClick('modify')}><a>Modify Groups</a></li>

        </ul>
      </div>

      {visible && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="modal modal-open">
            <div className="modal-box relative">
              <button className="btn btn-sm btn-circle absolute right-2 top-2" onClick={handleCancel}>✕</button>
              {selectedOption === 'create' && <NewGroup onClose={handleCancel} />}
              {selectedOption === 'modify' && <ModifyGroup onClose={handleCancel} id={id} />}

            </div>
          </div>
        </div>
      )}
    </div>
    break;
    case AdminLevel.ADMIN:
      return  <div>
      <div className="dropdown">
        <label tabIndex={0} className="btn mx-2 btn-inactive">Groups</label>
        <ul tabIndex={0} className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52 z-50">
          <li onClick={() => handleMenuClick('modify')}><a>Modify My Group</a></li>

        </ul>
      </div>

      {visible && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="modal modal-open">
            <div className="modal-box relative">
              <button className="btn btn-sm btn-circle absolute right-2 top-2" onClick={handleCancel}>✕</button>
              {selectedOption === 'create' && <NewGroup onClose={handleCancel} />}
              {selectedOption === 'modify' && <ModifyGroup onClose={handleCancel} id={id} />}

            </div>
          </div>
        </div>
      )}
    </div>
    break;
    case AdminLevel.USER:
      return <div>
      <div className="dropdown">
        <label tabIndex={0} className="btn mx-2 btn-inactive">Groups</label>
        <ul tabIndex={0} className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52 z-50">
        <li onClick={() => handleMenuClick('create')}><a>New Group</a></li>
          {isGroupMember && (<li onClick={() => handleMenuClick('modify')}><a>My Group</a></li>) }
        </ul>
      </div>

      {visible && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="modal modal-open">
            <div className="modal-box relative">
              <button className="btn btn-sm btn-circle absolute right-2 top-2" onClick={handleCancel}>✕</button>
              {selectedOption === 'create' && <NewGroup onClose={handleCancel} />}
              {selectedOption === 'modify' && <ModifyGroup onClose={handleCancel} id={id} />}
            </div>
          </div>
        </div>
      )}
    </div>
    break
  }
};

export default Group;
