import React, { useState, useEffect } from 'react';
import { Line, CartesianGrid, Area, XAxis, YAxis, Tooltip, ResponsiveContainer, ComposedChart, Bar } from 'recharts';
import { DateTime } from 'luxon';

interface DataItem {
  id: string;
  startTime: DateTime | null;
  deviceId: string;
  pain: number | null;
  pre: boolean;
}

interface PainGraphProps {
  data: DataItem[];
}

interface CustomTooltipProps {
  active?: boolean;
  payload?: any;
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;

    return (
      <div className="custom-tooltip" style={{ backgroundColor: '#fff', padding: '10px', border: '1px solid #ccc', fontSize: '12px' }}>
        <p>Date: {data.startTime?.toFormat('dd/MM/yyyy HH:mm')}</p>
        {(data.pre === true ?
        <p>Pain Pre-Treatment: {data.pain}</p>
        : 
        <p>Pain Post-Treatment: {data.pain}</p>
        )}
      </div>
    );
  }

  return null;
};

const initialLineVisibility = [true, true, false, false, true, true];

const PainGraph: React.FC<PainGraphProps> = ({ data }) => {
  const [lineVisibility, setLineVisibility] = useState<boolean[]>(initialLineVisibility);
  const [currentStart, setCurrentStart] = useState<DateTime>(DateTime.now().startOf('week'));
  const [currentEnd, setCurrentEnd] = useState<DateTime>(DateTime.now().endOf('week'));
  const [filteredData, setFilteredData] = useState<DataItem[]>([]);
  const [showTable, setShowTable] = useState<boolean>(false);
  const [daysShown, setDaysShown] = useState<number>(7);

  const toggleLineVisibility = (index: number) => {
    const newVisibility = [...lineVisibility];
    newVisibility[index] = !newVisibility[index];
    setLineVisibility(newVisibility);
  };

  const handlePrevious = () => {
    if (daysShown === 7) {
      const newStart = currentStart.minus({ weeks: 1 });
      const newEnd = currentEnd.minus({ weeks: 1 });
      setCurrentStart(newStart);
      setCurrentEnd(newEnd);
    } else {
      const newStart = currentStart.minus({ days: 1 });
      const newEnd = currentEnd.minus({ days: 1 });
      setCurrentStart(newStart);
      setCurrentEnd(newEnd);
    }
  };

  const handleNext = () => {
    if (daysShown === 7) {
      const newStart = currentStart.plus({ weeks: 1 });
      const newEnd = currentEnd.plus({ weeks: 1 });
      setCurrentStart(newStart);
      setCurrentEnd(newEnd);
    } else {
      const newStart = currentStart.plus({ days: 1 });
      const newEnd = currentEnd.plus({ days: 1 });
      setCurrentStart(newStart);
      setCurrentEnd(newEnd);
    }
  };

  const handleTimeRangeChange = (days: number) => {
    setDaysShown(days);
    if (days === 7) {
      setCurrentStart(DateTime.now().startOf('week'));
      setCurrentEnd(DateTime.now().endOf('week'));
    } else {
      setCurrentStart(DateTime.now().startOf('day'));
      setCurrentEnd(DateTime.now().endOf('day'));
    }
  };

  useEffect(() => {
    const filterData = (start: DateTime, end: DateTime, dataSource: DataItem[]) => {
      return dataSource.filter(item => {
        const itemDate = item.startTime;
        return itemDate && itemDate >= start && itemDate <= end;
      });
    };

    const filtered = filterData(currentStart, currentEnd, data);
    setFilteredData(filtered);
  }, [data, currentStart, currentEnd]);

  const transformedData = filteredData.map(item => ({
    ...item,
    timeStamp: item.startTime ? item.startTime.toMillis() : 0,
  }));

  const ticks = daysShown === 7 
    ? Array.from({ length: 7 }).map((_, i) => currentStart.plus({ days: i }).toMillis())
    : Array.from({ length: 24 }).map((_, i) => currentStart.plus({ hours: i }).toMillis());

  const formatTick = (tick: number) => DateTime.fromMillis(tick).toFormat(daysShown === 7 ? 'EEE dd/MM' : 'HH:mm');

  const isNextDisabled = daysShown === 7
    ? currentEnd >= DateTime.now().endOf('week')
    : currentEnd >= DateTime.now().endOf('day');

  return (
    <div className="card" style={{height: '500px'}}>
      <h2 className="text-l font-semibold p-4 text-center">Pain</h2>
      <div className="flex justify-between items-center mb-4 p-4">
        <button className="btn w-30" onClick={handlePrevious}>← Previous {daysShown === 7 ? 'Week' : 'Day'}</button>
        <h2>{currentStart.toFormat('dd/MM/yyyy')} - {currentEnd.toFormat('dd/MM/yyyy')}</h2>
        <button className="btn w-30" onClick={handleNext} disabled={isNextDisabled}>Next {daysShown === 7 ? 'Week' : 'Day'} →</button>
        <div className="flex">
          <button
            key={7}
            className={`btn mx-2 p-2 w-20 ${daysShown === 7 ? 'btn-active' : 'btn-inactive'}`}
            onClick={() => handleTimeRangeChange(7)}
          >
            7 Days
          </button>
          <button
            key={1}
            className={`btn mx-2 p-2 w-20 ${daysShown === 1 ? 'btn-active' : 'btn-inactive'}`}
            onClick={() => handleTimeRangeChange(1)}
          >
            Single Day
          </button>
        </div>
        
      </div>
      {(
        <div style={{height: '200px'}}>
          <ResponsiveContainer>
            <ComposedChart
              data={transformedData}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <Tooltip content={<CustomTooltip />} />
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                interval={0}
                ticks={ticks}
                tickFormatter={formatTick}
                dataKey="timeStamp"
                type="number"
                domain={[currentStart.toMillis(), currentEnd.toMillis()]}
                scale="time"
                style={{ fontSize: '12px' }}
              />
              <YAxis
                domain={[0, 10]}
                ticks={[0, 5, 10]}
                axisLine={{ stroke: '#AAAAAA' }}
                tickLine={{ stroke: '#AAAAAA' }}
                tick={{ fill: '#000000', fontSize:'12px' }}
              />
              {lineVisibility[0] && (
                <>
                  <Area type="monotone" dataKey="pain" stroke="#F60E38" fill="rgba(255, 14, 56, 0.1)" dot={{ r: 0 }} isAnimationActive={false} />
                  {/* <Line type="monotone" strokeWidth="2" dataKey="pain" stroke="#F60E38" dot={{ r: 0 }} isAnimationActive={false} /> */}
                </>
              )} 
            
                         </ComposedChart>
          </ResponsiveContainer>
          {/* <ul className="menu menu-horizontal bg-base-100 rounded-box">
            <li><a className="bg-base-100 btn" style={{ color: '#F60E38', opacity: lineVisibility[0] ? '1' : '0.2' }} onClick={() => toggleLineVisibility(0)}>Pain</a></li>

          </ul> */}
        </div>
      )}
    </div>
  );
};

export default PainGraph;
