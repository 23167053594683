import React, { useState, useEffect } from 'react';
import { Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer, ComposedChart } from 'recharts';
import { DateTime } from 'luxon';
import { exportAssessmentsToCsv } from "../../utils";

interface DataItem {
  id: string;
  startTime: DateTime | null;
  deviceId: string;
  flexibilityScore1: number;
  flexibilityScore2: number;
  flexibilityScore3: number;
  flexibilityScore4: number;
  flexibilityScore5: number;
  flexibilityScore6: number;
}

interface AssessmentGraphProps {
  data: DataItem[];
  userEmail: string | null | undefined;
}

interface CustomTooltipProps {
  active?: boolean;
  payload?: any;
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;

    return (
      <div className="custom-tooltip" style={{ backgroundColor: '#fff', padding: '10px', border: '1px solid #ccc', fontSize: '12px' }}>
        <p>Date: {data.startTime?.toFormat('dd/MM/yyyy HH:mm')}</p>
        <p>Flexion: {data.flexibilityScore1}°</p>
        <p>Extension: {data.flexibilityScore2}°</p>
        <p>Left Rotation: {data.flexibilityScore3}°</p>
        <p>Right Rotation: {data.flexibilityScore4}°</p>
        <p>Left Lateral: {data.flexibilityScore5}°</p>
        <p>Right Lateral: {data.flexibilityScore6}°</p>
      </div>
    );
  }

  return null;
};

const initialLineVisibility = [true, true, true, true, true, true];

const AssessmentGraph: React.FC<AssessmentGraphProps> = ({ data, userEmail }) => {
  const [lineVisibility, setLineVisibility] = useState<boolean[]>(initialLineVisibility);
  const [currentStart, setCurrentStart] = useState<DateTime>(DateTime.now().startOf('week'));
  const [currentEnd, setCurrentEnd] = useState<DateTime>(DateTime.now().endOf('week'));
  const [filteredData, setFilteredData] = useState<DataItem[]>([]);
  const [showTable, setShowTable] = useState<boolean>(false);
  const [daysShown, setDaysShown] = useState<number>(7);

  const toggleLineVisibility = (index: number) => {
    const newVisibility = [...lineVisibility];
    newVisibility[index] = !newVisibility[index];
    setLineVisibility(newVisibility);
  };

  const handlePrevious = () => {
    if (daysShown === 7) {
      setCurrentStart(currentStart.minus({ weeks: 1 }));
      setCurrentEnd(currentEnd.minus({ weeks: 1 }));
    } else {
      setCurrentStart(currentStart.minus({ days: 1 }));
      setCurrentEnd(currentEnd.minus({ days: 1 }));
    }
  };

  const handleNext = () => {
    if (daysShown === 7) {
      setCurrentStart(currentStart.plus({ weeks: 1 }));
      setCurrentEnd(currentEnd.plus({ weeks: 1 }));
    } else {
      setCurrentStart(currentStart.plus({ days: 1 }));
      setCurrentEnd(currentEnd.plus({ days: 1 }));
    }
  };

  const handleTimeRangeChange = (days: number) => {
    setDaysShown(days);
    if (days === 7) {
      setCurrentStart(DateTime.now().startOf('week'));
      setCurrentEnd(DateTime.now().endOf('week'));
    } else {
      setCurrentStart(DateTime.now().startOf('day'));
      setCurrentEnd(DateTime.now().endOf('day'));
    }
  };

  useEffect(() => {
    const filterData = (start: DateTime, end: DateTime, dataSource: DataItem[]) => {
      return dataSource.filter(item => {
        const itemDate = item.startTime;
        return itemDate && itemDate >= start && itemDate <= end;
      });
    };

    const filtered = filterData(currentStart, currentEnd, data);
    setFilteredData(filtered);
  }, [data, currentStart, currentEnd]);

  const transformedData = filteredData.map(item => ({
    ...item,
    timeStamp: item.startTime ? item.startTime.toMillis() : 0,
  }));

  const ticks = daysShown === 7 
    ? Array.from({ length: 7 }).map((_, i) => currentStart.plus({ days: i }).toMillis())
    : Array.from({ length: 24 }).map((_, i) => currentStart.plus({ hours: i }).toMillis());

  const formatTick = (tick: number) => DateTime.fromMillis(tick).toFormat(daysShown === 7 ? 'EEE dd/MM' : 'HH:mm');

  const isNextDisabled = currentEnd >= DateTime.now().endOf('week');

  return (
    <div className="card" style={{height: '500px'}}>
      <h2 className="text-l font-semibold p-4 text-center">Assessments</h2>
      <span
        className="text-blue-500 cursor-pointer underline text-center"
        style={{ fontSize: '12px' }}
        onClick={() => exportAssessmentsToCsv("assessments.csv", data, userEmail!)} // Export all assessment data, not just the filtered
      >
        Export all to CSV
      </span>
      <div className="flex justify-between items-center mb-4 p-4">
        <button className="btn w-30" onClick={handlePrevious}>← Previous Week</button>
        <h2>{currentStart.toFormat('dd/MM/yyyy')} - {currentEnd.toFormat('dd/MM/yyyy')}</h2>
        <button className="btn w-30" onClick={handleNext} disabled={isNextDisabled}>Next Week →</button>
        <div className="flex">
          <button
            key={7}
            className={`btn mx-2 p-2 w-20 ${daysShown === 7 ? 'btn-active' : 'btn-inactive'}`}
            onClick={() => handleTimeRangeChange(7)}
          >
            7 Days
          </button>
          <button
            key={1}
            className={`btn mx-2 p-2 w-20 ${daysShown === 1 ? 'btn-active' : 'btn-inactive'}`}
            onClick={() => handleTimeRangeChange(1)}
          >
            Single Day
          </button>
        </div>
        <h3 style={{ position: 'relative', left: '0px', fontSize: '14px'}}>Table Display</h3>
        <label className="switch" style={{ right: '30px' }}>
          <input type="checkbox" checked={showTable} onChange={() => setShowTable(!showTable)} />
          <span className="slider"></span>
        </label>
      </div>
      {!showTable ? (
        <div style={{height: '200px'}}>
          <ResponsiveContainer>
            <ComposedChart
              data={transformedData}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <Tooltip content={<CustomTooltip />} />
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                interval={0}
                ticks={ticks}
                tickFormatter={formatTick}
                dataKey="timeStamp"
                type="number"
                domain={[currentStart.toMillis(), currentEnd.toMillis()]}
                scale="time"
                style={{ fontSize: '12px' }}
              />
              <YAxis domain={[0, 90]} ticks={[30, 60]} axisLine={{ stroke: '#AAAAAA' }} tickLine={{ stroke: '#AAAAAA' }} tick={{ fill: '#000000', fontSize: '12px' }} />
              {lineVisibility[0] && <Line type="monotone" strokeWidth="1.5" dataKey="flexibilityScore1" stroke="#4880B8" dot={{ r: 2 }} isAnimationActive={false}/>}
              {lineVisibility[1] && <Line type="monotone" strokeWidth="1.5" dataKey="flexibilityScore2" stroke="#73E38C" dot={{ r: 2 }} isAnimationActive={false}/>}
              {lineVisibility[2] && <Line type="monotone" strokeWidth="1.5" dataKey="flexibilityScore3" stroke="#9747ff" dot={{ r: 2 }} isAnimationActive={false}/>}
              {lineVisibility[3] && <Line type="monotone" strokeWidth="1.5" dataKey="flexibilityScore4" stroke="#56c1ca" dot={{ r: 2 }} isAnimationActive={false}/>}
              {lineVisibility[4] && <Line type="monotone" strokeWidth="1.5" dataKey="flexibilityScore5" stroke="#F60E38" dot={{ r: 2 }} isAnimationActive={false}/>}
              {lineVisibility[5] && <Line type="monotone" strokeWidth="1.5" dataKey="flexibilityScore6" stroke="#FFA452" dot={{ r: 2 }} isAnimationActive={false}/>}
            </ComposedChart>
          </ResponsiveContainer>
          <ul className="menu menu-horizontal bg-base-100 rounded-box">
            <li><a className="bg-base-100 btn" style={{ color: '#4880B8', opacity: lineVisibility[0] ? '1' : '0.2' }} onClick={() => toggleLineVisibility(0)}>Flexion</a></li>
            <li><a className="bg-base-100 btn" style={{ color: '#73E38C', opacity: lineVisibility[1] ? '1' : '0.2' }} onClick={() => toggleLineVisibility(1)}>Extension</a></li>
            <li><a className="bg-base-100 btn" style={{ color: '#9747ff', opacity: lineVisibility[2] ? '1' : '0.2' }} onClick={() => toggleLineVisibility(2)}>Left Rotation</a></li>
            <li><a className="bg-base-100 btn" style={{ color: '#56c1ca', opacity: lineVisibility[3] ? '1' : '0.2' }} onClick={() => toggleLineVisibility(3)}>Right Rotation</a></li>
            <li><a className="bg-base-100 btn" style={{ color: '#F60E38', opacity: lineVisibility[4] ? '1' : '0.2' }} onClick={() => toggleLineVisibility(4)}>Left Lateral</a></li>
            <li><a className="bg-base-100 btn" style={{ color: '#FFA452', opacity: lineVisibility[5] ? '1' : '0.2' }} onClick={() => toggleLineVisibility(5)}>Right Lateral</a></li>
          </ul>
        </div>
      ) : (
        <div className="table-responsive" style={{ height: '300px' }}>
          <table className="table table-zebra" style={{ fontSize: '12px' }}>
            <thead>
              <tr>
                <th>Device</th>
                <th>Start Time</th>
                <th>Flexion</th>
                <th>Extension</th>
                <th>Left Rotation</th>
                <th>Right Rotation</th>
                <th>Left Lateral</th>
                <th>Right Lateral</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((row) => (
                <tr key={row.id}>
                  <td>{row.deviceId}</td>
                  <td>{row.startTime?.toFormat('yyyy-MM-dd HH:mm')}</td>
                  <td>{row.flexibilityScore1}</td>
                  <td>{row.flexibilityScore2}</td>
                  <td>{row.flexibilityScore3}</td>
                  <td>{row.flexibilityScore4}</td>
                  <td>{row.flexibilityScore5}</td>
                  <td>{row.flexibilityScore6}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default AssessmentGraph;